<template>
  <div class="login_div">
    <div class="top_logo" v-if="!isMobile">
        <img src="@/assets/images/yeschat.png" class="logo_img" />
    </div>
    <div class="account-container">
        <div class="container">
            <div class="left-container" v-if="!isMobile">
                <h2>一个让你惊呼 <span class="text">Yes</span> 的<br>对话式内容生产引擎</h2>
                <div class="boxs">
                    <div class="row">
                        <div class="box">写代码</div>
                        <div class="box">debug</div>
                        <div class="box">文案润色</div>
                        <div class="box mleft1">文案二创</div>
                        <div class="box">知识问答</div>
                    </div>
                    <div class="row">
                        <div class="box">专业翻译</div>
                        <div class="box">法律助手</div>
                        <div class="box">撰写专利</div>
                        <div class="box">论文润色</div>
                    </div>
                    <div class="row">
                        <div class="box">写公文</div>
                        <div class="box">股票分析</div>
                        <div class="box">数据分析</div>
                        <div class="box">截图转前端代码</div>
                    </div>
                </div>
            </div>
            <div class="right-container">
                <!-- 登录 -->
                <div v-if="isShow">
                    

                    <div v-if="!isMobile"><h1 >登录账号</h1></div>
                    <div v-if="isMobile">
                        <img src="@/assets/images/yeschat.png" class="mobile_logo_img" />
                        <div class="mobile_zhuce">登录账号</div>
                    </div>
                    <div class="input-list">
                        <div class="input-list-con">
                            <div class="list">
                                <input type="text" v-model="param.username" class="input-text" placeholder="账号">
                                <span class="span" @click="dialogA=true">忘记账号?</span>
                            </div>
                            <div class="list">
                                <input type="password" v-model="param.password" class="input-text" placeholder="密码" >
                                <span class="span" @click="dialogC=true">忘记密码?</span>
                            </div>
                            <div class="submit">
                                <div class="text dengluMs" style="margin-bottom: 28px;">没有账号？<span class="span" @click="isShow=false">立即注册</span></div>
                                <div class="button dengluButton" @click="handleLogin()">登录</div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- 注册 -->
                <div v-else>
                    <div v-if="!isMobile">
                        <h1 style="margin-bottom: 38px;">
                            <p>注册账号</p>
                            <div class="reg-tip">今日注册，畅享免费试用！</div>
                        </h1>
                    </div>
                    <div v-if="isMobile">
                        <img src="@/assets/images/yeschat.png" class="mobile_logo_img" />
                        <div class="mobile_zhuce">注册账号</div>
                    </div>
                    <div class="input-list">
                        <div class="input-list-con">
                            <div class="list">
                                <input type="text" v-model="zcparam.username" class="input-text" placeholder="请设置账号">
                                <div class="ms">账号长度为6-24位，仅限字母、数字和@._-符号</div>
                            </div>
                            <div class="list">
                                <input type="password" v-model="zcparam.password" class="input-text" placeholder="请设置密码" >
                                <!-- <el-input placeholder="请输入密码" v-model="zcparam.password" show-password></el-input> -->
                                <div class="ms">密码长度为8-24位</div>
                            </div>
                            <div class="list">
                                <input type="text" v-model="zcparam.register_code" class="input-text input-zcm" placeholder="验证码" >
                                <div class="djhq" @click="dialogD=true">点击获取验证码</div>
                            </div>
                            <div class="submit">
                                <div class="button" @click="handleRegister()">注册</div>
                                <div class="text" style="margin-top: 18px;">已有账号？<span class="span" @click="isShow=true">立即登录</span></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-dialog title="忘记账号" :width="isMobile?'90%':'488px'" :visible.sync="dialogA">
        <div class="dialog_box">
            <input type="text" v-model="findusername_param.payno" class="input-text" placeholder="输入购买套餐时的商家订单号" >
            <div class="ms"><i class="el-icon-warning" ></i>打开支付宝，找到购买套餐时的订单，点击“更多”，即可找到“商家订单号”。<br/>注意！是商家订单号，不是订单号！</div>
            <div class="button" @click="getfindusername()">提交</div>
        </div>
    </el-dialog>
    <el-dialog title="提示成功" :width="isMobile?'90%':'488px'" :visible.sync="dialogB">
        <div class="dialog_box">
            <!-- <div class="label">订单号：1111111</div> -->
            <div class="label">用户名：{{name}}</div>
            <div class="button" @click="dialogB=false;param.username=name;">立即登录</div>
        </div>
    </el-dialog>
    <el-dialog title="忘记密码" :width="isMobile?'90%':'440px'" :visible.sync="dialogC">
        <div class="dialog_box">
            <input type="text" v-model="findpassword_param.username" class="input-text" placeholder="账号" >
            <input type="text" v-model="findpassword_param.payno" class="input-text" placeholder="商家订单号" >
            <div class="ms"><i class="el-icon-warning" ></i>打开支付宝，找到购买套餐时的订单，点击“更多”， 即可找到“商家订单号”。<br/>注意！是商家订单号，不是订单号！</div>
            <input type="text" v-model="findpassword_param.new_password" class="input-text" placeholder="新密码" >
            <!-- <div class="ms_pass">密码长度为8-24位</div> -->
            <div class="button" @click="getfindpassword()">确认</div>
        </div>
    </el-dialog>
    <el-dialog title="" :width="isMobile?'90%':'930px'" :visible.sync="dialogD">
        <div class="dialog_box">
            <div style="text-align: center;">
                不会操作？<a href="" @click.prevent="serviceDialog=true" style="color:#0366d6">点击这里联系人工客服</a>
            </div>
            <img class="img_zcm" src="@/assets/images/zcm_yd.png" v-if="isMobile" style="margin-top: 20px;">
            <img class="img_zcm" src="@/assets/images/zcm.png" v-else>
        </div>
    </el-dialog>
    
    <el-dialog :close-on-click-modal="false" title="扫码添加客服微信" :width="isMobile?'90%':'402px'" :visible.sync="serviceDialog">
        <div class="dialog_box" style="text-align: center;">
            <img src="@/assets/images/new/kf.jpg" class="kf_img" />
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { login,register,findusername,findpassword } from "@/api/user";

export default {
    data() {
        return {
            dialogA:false,
            dialogB:false,
            dialogC:false,
            dialogD:false,
            serviceDialog: false,
            isShow:false,
            
            param:{
                // "username":"admin222",
                // "password":"11112222",
                "username":"",
                "password":"",
            },
            zcparam:{
                "username":"",
                "password":"",
                "register_code":""
                // "username":"admin222",
                // "password":"11112222",
                // "register_code":"32ju1"
            },
            findusername_param:{
                "payno":"",
            },
            name:"",
            findpassword_param:{
                "payno":"",
                "username":"",
                "new_password":"",
                // "payno":"1$admin222$1677228143.6982489",
                // "username":"admin222",
                // "new_password":"1234567",
            },
            isMobile:"",
        };
    },
    created() {
        let that = this;
        that.isMobile=navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    },
    methods: {
        //找回账号
        getfindusername() {
            var that = this;
            findusername(that.findusername_param).then((res) => {
                if(res.code==0){
                    that.name=res.result.message;
                    that.dialogA=false;
                    that.dialogB=true;
                }
            });
        },
        //找回密码
        getfindpassword() {
            var that = this;
            findpassword(that.findpassword_param).then((res) => {
                if(res.code==0){
                    that.$message.success("修改成功~");
                    that.dialogC=true;
                }
            });
        },
        //登录
        handleLogin() {
            var that = this;
            if(!that.param.username){
                that.$message.error("账号不能为空");
                return false;
            }
            if(!that.param.password){
                that.$message.error("密码不能为空");
                return false;
            }
            login(that.param).then((res) => {
                if(res.code==0){
                    that.$router.push({
                        path: "/index",
                    });
                }
            });
        },
        //注册
        handleRegister() {
            var that = this;
            const regex = /^[a-zA-Z0-9@._-]+$/;
            if(!that.zcparam.username){
                that.$message.error("账号不能为空");
                return false;
            }
            if(that.zcparam.username.length<6||that.zcparam.username.length>24){
                that.$message.error("账号6-24位");
                return false;
            }
            if(!that.zcparam.password){
                that.$message.error("密码不能为空");
                return false;
            }
            if(that.zcparam.password.length<8||that.zcparam.password.length>24){
                that.$message.error("密码8-24位");
                return false;
            }
            if(!that.zcparam.register_code){
                that.$message.error("注册码不能为空");
                return false;
            }
            if(!regex.test(that.zcparam.username)){
                that.$message.error("账号格式不对，仅限字母、数字和@._-符号");
                return false;
            }
            register(that.zcparam).then((res) => {
                if(res.code==0){
                    that.$message.success("注册成功~");
                    //调用登录
                    that.param={
                        "username":that.zcparam.username,
                        "password":that.zcparam.password,
                    }
                    that.handleLogin();
                    // that.isShow=true;
                }
            });
        },
    },
};
</script>
